<template>
    <div class="container ">
        <br />
        <div ref="joinTeam" class="box" style="text-align:center"> 
           <div @click="$router.push('/dashboard')" style='text-align:left; cursor:pointer; '> <i  style=" " class="fas fa-chevron-circle-left"></i> Back</div>
        <h3>  Join a Team</h3>
        <div class="input-wrapper">
  <PincodeInput
    v-model="pin"
    @input="runPin"
    placeholder="0"
    :length="7"
  />
</div>
<br />
<div v-if="isWrong"><b>The pin is not right, try again!</b><br /></div>  or<br /><br />
          <button @click="$router.push('/createTeam')" class="btn btn-success">+ Create Your Own Team</button>
        </div>
      
           
    </div>
</template>
<script>
import PincodeInput from 'vue-pincode-input';
import firebase from "firebase";

require("firebase/functions");

 var functions = firebase.functions()
export default {
    methods:{
        runPin: async function(){
            var loader
            try {
           if (this.pin.length != 7){return}
                this.isWrong = false

                 loader = this.$loading.show({
                  // Optional parameters
                  container:  this.$refs.joinTeam,
          
                });
        
               var  addMessage = functions.httpsCallable('joinByCode')
                
              var result = await  addMessage({ join_pin: parseInt(this.pin) })

    console.log(result)
     loader.hide()

     if (!result.data.success){
         this.isWrong = true
         this.pin = ""
     }else{
                 this.$router.push('/dashboard')

     }

            }catch(error){
                loader.hide()
console.error(error)
            }
        },
    },
    components:{
        PincodeInput
},
    data() {
    return {
        pin: "",
        isWrong: false,
    }
    }
}
</script>

<style scoped>
.box{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
   
}
</style>